<script>
import inputMixin from '@/vuetify-mixins/input-mixin'

export default {
	lang: 'shop',
	mixins: [inputMixin],
	props: {
		countryId: Number,
		label: String,
		shippingContext: Boolean,
		countriesFilter: Function,
	},
	model: {
		prop: 'countryId',
		event: 'updateModel',
	},
	data() {
		return {
			countries: [],
			loading: false,
		}
	},
	computed: {
		selectedCountry() {
			return this.countries.find((country) => country.id == this.countryId)
		},
	},
	watch: {
		selectedCountry(value) {
			this.$emit('country-selected', value)
		},
	},
	methods: {
		async loadData() {
			await this.$shopApi.get({
				url: '/location/countries',
				query: { shippingContext: Number(this.shippingContext) },
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					if (this.countriesFilter) {
						data.countries = data.countries.filter(this.countriesFilter)
					}
					this.countries = data.countries.sort((a, b) => (a.name > b.name ? 1 : -1))
					if (this.countries.length == 1) {
						this.$nextTick(() => {
							this.onInput(this.countries[0].id)
						})
					}
				},
				cache: true,
			})
		},
		onInput($event) {
			this.$emit('updateModel', $event)
		},
		focus() {
			this.$refs.selector.focus()
		},
	},
	mounted() {
		this.loadData()
	},
}
</script>

<template>
	<Autocomplete
		ref="selector"
		:label="label || $lang('País')"
		:placeholder="$lang('Buscar...')"
		@input="onInput"
		:items="countries"
		:multiple="false"
		item-text="name"
		item-value="id"
		:loading="loading"
		:no-data-text="$lang('No se han encontrado resultados...')"
		:hide-no-data="loading"
		:value="countryId"
		:error-messages="validationErrors"
		v-bind="$attrs"
		prepend-icon="mdi-earth"
	/>
</template>

